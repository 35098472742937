var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"surperDetail"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"tu"},[_c('img',{staticClass:"img",attrs:{"src":_vm.memberHeader.cover_img,"alt":""}}),_c('div',{staticClass:"name"},[(
            _vm.memberHeader.display_content &&
              _vm.memberHeader.display_content.some(item => item == 1)
          )?_c('div',{staticClass:"title hidetext",style:(`color:${_vm.selectSpecList.titleC}`),attrs:{"title":_vm.memberHeader.name}},[_vm._v(" "+_vm._s(_vm.memberHeader.name)+" ")]):_vm._e(),(
            _vm.memberHeader.display_content &&
              _vm.memberHeader.display_content.some(item => item == 2)
          )?_c('div',{staticClass:"sub_title hidetext",style:(`color:${_vm.selectSpecList.subtitleC}`),attrs:{"title":_vm.memberHeader.subtitle}},[_vm._v(" "+_vm._s(_vm.memberHeader.subtitle)+" ")]):_vm._e(),(
            _vm.memberHeader.display_content &&
              _vm.memberHeader.display_content.some(item => item == 3)
          )?_c('div',{staticClass:"quanyi fs12 hidetext",style:(`color:${_vm.selectSpecList.equityC}`)},[(_vm.memberHeader.buy_equity_name)?_c('p',{staticStyle:{"margin-bottom":"7px"}},[_vm._v(" ·"+_vm._s(_vm.memberHeader.buy_equity_name)+" ")]):_vm._e(),(_vm.memberHeader.free_equity_name)?_c('p',[_vm._v(" ·"+_vm._s(_vm.memberHeader.free_equity_name)+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.memberHeader.name)+" "),(_vm.memberHeader.status == 1)?_c('div',{staticClass:"tip1"},[_vm._v("已启用")]):_c('div',{staticClass:"tip2"},[_vm._v("已停用")])]),_c('div',{staticClass:"subtitle"},[_vm._v("副标题："+_vm._s(_vm.memberHeader.subtitle))]),_c('div',{staticClass:"quanyi"},[_vm._v(" 权益名称： "),(_vm.memberHeader.free_equity_name)?_c('span',[_vm._v(" "+_vm._s(_vm.memberHeader.free_equity_name)+" ")]):_vm._e(),(_vm.memberHeader.buy_equity_name)?_c('span',[_vm._v(" "+_vm._s(_vm.memberHeader.buy_equity_name)+" ")]):_vm._e()]),_c('div',{staticClass:"validty"},[_vm._v(" 有效期： "),(_vm.memberHeader.validity == 0)?_c('span',[_vm._v("长期有效")]):_c('span',[_vm._v(_vm._s(_vm.memberHeader.validity)+"天")])]),_c('div',{staticClass:"btns"},[_c('el-button',{staticStyle:{"width":"100px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$router.push({
              path: '/manageSuperMember/newSupermember',
              query: { id: _vm.m_id },
            })}}},[_vm._v(" 编辑 ")]),(_vm.memberHeader.status == 1)?_c('el-button',{staticStyle:{"width":"100px","margin-left":"20px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.tuiguang()}}},[_vm._v(" 推广 ")]):_vm._e(),(_vm.memberHeader.status == 1)?_c('el-button',{staticStyle:{"width":"100px","margin-left":"20px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.setStatus(1)}}},[_vm._v(" 停用 ")]):_c('el-button',{staticStyle:{"width":"100px","margin-left":"20px","background":"#FF3535"},attrs:{"type":"danger","size":"small"},on:{"click":function($event){return _vm.setStatus(2)}}},[_vm._v(" 启用 ")])],1)])]),_c('div',{staticClass:"main_contain"},[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"会员列表","name":"1"}}),_c('el-tab-pane',{attrs:{"label":"审核列表","name":"2"}})],1),_c('div',{staticClass:"tablist"},[(_vm.activeName == 1)?_c('member-list'):_vm._e(),(_vm.activeName == 2)?_c('examine-list'):_vm._e()],1)],1),(_vm.status)?_c('extension',{attrs:{"selectrow":_vm.memberHeader,"dialogstatus":_vm.status},on:{"update:dialogstatus":function($event){_vm.status=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }