<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-08-03 11:51:53
 * @LastEditTime: 2022-08-03 17:28:07
 * @LastEditors: cyy
 * @Description: 查看表单信息
 * @FilePath: \teacherdingding.com\src\views\Survey\manageSuperMember\components\formFields.vue
-->
<template>
  <div class="formFields">
    <el-dialog
      title="查看表单信息"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <div class="item" v-for="(item, index) in fieldsList" :key="index">
          <div class="name">{{ item.qname }}</div>
          <div class="result">{{ item.result }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'formFields',

  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },

    fieldsList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.formFields {
  ::v-deep .el-dialog__body {
    padding: 0px 20px 20px;
  }
  .dialogVisible_bottom {
    .item {
      height: 74px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.5;
      border-bottom: 1px #eaeaea solid;
      .name {
        font-size: 13px;
        color: #333333;
        margin-bottom: 10px;
      }
      .result {
        font-size: 12px;
        color: #666666;
      }
    }
  }
}
</style>
