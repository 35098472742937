<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-08-02 18:41:04
 * @LastEditTime: 2022-08-08 17:12:54
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\Survey\manageSuperMember\components\examineList.vue
-->
<template>
  <div class="examineList">
    <div class="top">
      <div class="search">
        <el-form
          ref="ruleIngredientsForm"
          label-width="100px"
          :model="search"
          class="flex-center"
          size="small"
        >
          <el-form-item label="关键词" class="mr20">
            <div class="search-item search-item1 ">
              <div class="vm select">
                <el-select v-model="search.search_type">
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="vm input">
                <el-input
                  :placeholder="placeholder"
                  v-model="search.search"
                  clearable
                ></el-input>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="申请时间" class="mr20">
            <div class="vm select ">
              <el-date-picker
                style="width:340px"
                v-model="search.c_time"
                @change="
                  selectedTime(arguments[0], ['small_c_time', 'large_c_time'])
                "
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                value-format="timestamp"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="审核状态" class="mr20">
            <div class="vm select ">
              <el-select v-model="search.status" style="width:300px">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <el-button size="medium" type="primary" @click="toSearch">搜索</el-button>
    </div>
    <pagination2
      :option="search2"
      class="childDialogBox"
      url="/member/auditList"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="用户" fixed min-width="100px">
            <template slot-scope="{ row }">
              <div class="user_contain">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name">{{ row.uname }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="手机号">
            <template slot-scope="{ row }">{{ row.umobile || '-' }}</template>
          </el-table-column>
          <el-table-column label="申请时间" min-width="160px">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="申请状态">
            <template slot-scope="{ row }">
              {{ row.status == 1 ? '待审核 ' : '已拒绝' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" min-width="250px">
            <template slot-scope="{ row }">
              <!-- 通过  |  拒绝  |  查看表单信息 -->
              <el-button
                type="text"
                @click="resRej(row, 1)"
                v-if="row.status == 1"
              >
                通过
              </el-button>
              <el-divider
                direction="vertical"
                v-if="row.status == 1"
              ></el-divider>
              <el-button
                type="text"
                @click="resRej(row, 2)"
                v-if="row.status == 1"
              >
                拒绝
              </el-button>
              <el-divider
                direction="vertical"
                v-if="row.status == 1 && row.is_submit == 1"
              ></el-divider>
              <el-button
                type="text"
                @click="formFieldsList(row)"
                v-if="row.is_submit == 1"
              >
                查看表单信息
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <!-- 查看表单信息 -->
    <form-fields
      v-if="fieldsStatus"
      :dialogstatus.sync="fieldsStatus"
      :fieldsList="fieldsList"
    ></form-fields>
  </div>
</template>

<script>
import formFields from './formFields'
export default {
  name: 'examineList',

  components: {
    formFields,
  },

  data() {
    return {
      fieldsList: [],

      // 查看表单信息状态
      fieldsStatus: false,

      search: {
        m_id: '',
        search: '',
        search_type: 1,
        small_c_time: '',
        large_c_time: '',
        status: 0,
        c_time: 0,
      },

      search2: {},

      // 搜索-
      options1: [
        { label: '用户名', value: 1, placeholder: '输入用户名' },
        { label: '手机号', value: 2, placeholder: '输入手机号' },
      ],

      options2: [
        { label: '全部', value: 0 },
        { label: '待审核', value: 1 },
        { label: '已拒绝', value: 2 },
      ],
    }
  },

  computed: {
    m_id() {
      return this.$route.query.id
    },

    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options1.find(item => item.value == this.search.search_type)
        .placeholder
    },
  },

  created() {
    this.search2 = _.assign({}, this.search, { m_id: this.m_id })
  },

  methods: {
    // 查看表单信息
    async formFieldsList(row) {
      const { data } = await this.$http({
        url: '/member/resultDetail',
        data: {
          me_id: row.me_id,
        },
      })
      this.fieldsList = data
      console.log(row)
      this.$nextTick(() => {
        this.fieldsStatus = true
      })
    },

    // 搜索
    toSearch() {
      this.search2 = _.assign({}, this.search, {
        m_id: this.m_id,
      })
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000
      })
    },

    resRej(row, val) {
      this.$confirm(
        `是否确定${val == 1 ? '通过' : '拒绝'}该学员的申请？`,
        val == 1 ? '通过' : '拒绝',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          this.setAuditStatus(row, val)
        })
        .catch(() => {})
    },

    async setAuditStatus(row, val) {
      await this.$http({
        url: '/member/setAuditStatus',
        data: {
          me_id: row.me_id,
          status: val,
        },
        successMsg: true,
      })
      this.$refs.childDialogBox.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.examineList {
  .top {
    margin-bottom: 28px;

    .search {
      margin-top: 28px;
      .middle-space {
        font-size: 14px;
        line-height: 19px;
        margin: 0 10px;
        color: rgba(51, 51, 51, 1);
      }
      ::v-deep .el-form {
        flex-wrap: wrap;
      }
      .search_type {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .el-form-item__content {
          height: auto;
        }
        .el-form-item {
          width: 33.3%;
          margin-bottom: 20px;
        }
      }
      ::v-deep .el-input,
      ::v-deep .select {
        width: 100%;
        .el-input__inner {
          width: 100%;
        }
      }
      ::v-deep .search-item1 {
        display: flex;
        width: 300px;
        .select {
          width: 130px;
          input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .input {
          width: 100%;
          input {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      ::v-deep .search-item2,
      ::v-deep .search-item6 {
        .select {
          width: 160px;
        }
      }
      ::v-deep .search-item3,
      ::v-deep .search-item4 {
        .el-date-editor {
          width: 292px;
        }
        .el-input__icon {
          line-height: 27px;
        }
      }
      ::v-deep .search-item5,
      ::v-deep .search-item7,
      ::v-deep .search-item8 {
        width: 300px;
        .el-input {
          // width: 130px;
          position: relative;
          input[type='number'] {
            padding-right: 50px;
          }
          .el-input-group__append {
            position: absolute;
            right: 0;
            background: none;
            top: 6px;
            height: 100%;
            border: 0;
            // line-height: 36px;
            width: auto;
            padding: 0;
            padding-right: 10px;
          }
        }
      }
    }
  }
  .childDialogBox {
    .user_contain {
      display: flex;
      align-content: center;
      .tu {
        width: 28px;
        height: 28px;
        background: #939191;
        border-radius: 14px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
}
</style>
