<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-08-02 14:43:02
 * @LastEditTime: 2022-08-15 18:30:04
 * @LastEditors: cyy
 * @Description:超级会员-会员列表
 * @FilePath: \teacherdingding.com\src\views\Survey\manageSuperMember\components\memberList.vue
-->
<template>
  <div class="memberList">
    <div class="top">
      <div class="add">
        <el-button
          type="primary"
          size="medium"
          style="width:130px"
          @click="statusAddMember = true"
        >
          添加会员
        </el-button>
      </div>
      <div class="search">
        <el-form
          ref="ruleIngredientsForm"
          label-width="100px"
          :model="search"
          class="flex-center"
          size="small"
          :rules="rules"
        >
          <el-form-item label="关键词" class="mr20">
            <div class="search-item search-item1 ">
              <div class="vm select">
                <el-select v-model="search.search_type">
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="vm input">
                <el-input
                  :placeholder="placeholder"
                  v-model="search.search"
                  clearable
                ></el-input>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="加入会员时间" class="mr20">
            <div class="vm select ">
              <el-date-picker
                style="width:340px"
                v-model="search.join_time"
                @change="
                  selectedTime(arguments[0], [
                    'small_join_time',
                    'large_join_time',
                  ])
                "
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                value-format="timestamp"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="会员到期时间" class="mr20">
            <div class="vm select ">
              <el-date-picker
                style="width:340px"
                v-model="search.expiration_time"
                @change="
                  selectedTime(arguments[0], [
                    'small_expiration_time',
                    'large_expiration_time',
                  ])
                "
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                value-format="timestamp"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="会员状态" class="mr20">
            <div class="vm select ">
              <el-select v-model="search.status" style="width:300px">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="剩余有效期" prop="large_valid_days">
            <div class="search-item8">
              <div class="vm select">
                <div class="flex-center" style="width:100%">
                  <el-input
                    size="small"
                    :value="search.small_valid_days"
                    @input="
                      search.small_valid_days = toNumber(arguments[0], true)
                    "
                    clearable
                  >
                    <template slot="append">天</template>
                  </el-input>
                  <span class="middle-space">至</span>
                  <el-input
                    size="small"
                    :value="search.large_valid_days"
                    @input="
                      search.large_valid_days = toNumber(arguments[0], true)
                    "
                    clearable
                  >
                    <template slot="append">天</template>
                  </el-input>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <el-button size="medium" type="primary" @click="toSearch">搜索</el-button>
    </div>

    <pagination2
      :option="search2"
      class="childDialogBox"
      url="/member/userList"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="用户" fixed min-width="150px">
            <template slot-scope="{ row }">
              <div class="user_contain">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name">{{ row.uname }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="手机号">
            <template slot-scope="{ row }">{{ row.umobile || '-' }}</template>
          </el-table-column>
          <el-table-column label="分组名称">
            <template slot-scope="{ row }">
              {{ row.gname || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="会员状态">
            <template slot-scope="{ row }">
              {{ row.status == 1 ? '有效 ' : '过期' }}
            </template>
          </el-table-column>
          <el-table-column label="剩余有效期(天)" min-width="130px">
            <template slot-scope="{ row }">
              {{ row.valid }}
            </template>
          </el-table-column>
          <el-table-column label="加入会员方式" min-width="160px">
            <template slot-scope="{ row }">
              {{ row.join_type == 1 ? '网校申请' : '后台导入' }}
            </template>
          </el-table-column>
          <el-table-column label="加入时间" min-width="160px">
            <template slot-scope="{ row }">
              {{ row.join_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="会员到期时间" min-width="160px">
            <template slot-scope="{ row }">
              {{ row.expiration_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column
            label="累计网校消费金额"
            min-width="140px"
            prop="order_amount"
          ></el-table-column>
          <el-table-column
            label="累计网校消费次数"
            min-width="140px"
            prop="order_quantity"
          ></el-table-column>
          <el-table-column label="操作" fixed="right" min-width="320px">
            <template slot-scope="{ row }">
              <!-- 移除  |  修改有效期  |  修改分组 -->
              <el-button type="text" @click="del(row)">
                移除
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="modifyTime(row)">
                修改有效期
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="modifyGroump(row)">
                修改分组
              </el-button>
              <el-divider
                direction="vertical"
                v-if="row.me_id != 0"
              ></el-divider>
              <el-button
                type="text"
                @click="formFieldsList(row)"
                v-if="row.me_id != 0"
              >
                查看表单信息
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <!--移动分组 -->
    <mobile-grouping
      v-if="mobileGroupingStatus"
      :dialogstatus.sync="mobileGroupingStatus"
      :selectTemplate="selectTemplate"
      @complete="complete"
    ></mobile-grouping>

    <!-- 修改有效期 -->
    <el-dialog
      title="修改有效期"
      :close-on-click-modal="false"
      :visible.sync="validStatus"
      width="442px"
    >
      <span>
        会员到期时间
        <el-date-picker
          v-model="validEndtime"
          size="medium"
          style="width:278px"
          class="ml20"
          type="datetime"
          placeholder="选择日期时间"
        ></el-date-picker>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="validStatusCancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" size="medium" @click="validStatusSubmit">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 添加用户 -->
    <addmember
      v-if="statusAddMember"
      :type="4"
      :dialogstatus.sync="statusAddMember"
      @addmemberList="addmemberList"
    ></addmember>

    <!-- 查看表单信息 -->
    <form-fields
      v-if="fieldsStatus"
      :dialogstatus.sync="fieldsStatus"
      :fieldsList="fieldsList"
    ></form-fields>
  </div>
</template>

<script>
import formFields from './formFields'
import addmember from '@cm/base/addmember'
import mobileGrouping from './mobileGrouping'
export default {
  name: 'memberList',

  components: {
    mobileGrouping,
    addmember,
    formFields,
  },

  data() {
    var validatePass2 = (rule, value, callback) => {
      if (
        typeof this.search.large_valid_days === 'number' &&
        this.search.large_valid_days < this.search.small_valid_days
      ) {
        this.tosearch = false
        callback(new Error('最大天数不能小于最小天数'))
      } else {
        this.tosearch = true
        callback()
      }
    }
    return {
      fieldsList: [],

      // 查看表单信息状态
      fieldsStatus: false,

      statusAddMember: false,

      validEndtime: '',

      validStatus: false,

      mobileGroupingStatus: false,
      selectTemplate: {},

      rules: {
        large_valid_days: [{ validator: validatePass2, trigger: 'blur' }],
      },

      search2: {},

      search: {
        m_id: '',
        join_time: 0,
        last_buy_time: 0,
        search_type: 1,
        search: '',
        small_join_time: '',
        large_join_time: '',
        small_expiration_time: '',
        large_expiration_time: '',
        status: 0,
        small_valid_days: '',
        large_valid_days: '',
      },

      options2: [
        { label: '全部', value: 0 },
        { label: '有效', value: 1 },
        { label: '过期', value: 2 },
      ],

      // 搜索-
      options1: [
        { label: '用户名', value: 1, placeholder: '输入用户名' },
        { label: '手机号', value: 2, placeholder: '输入手机号' },
      ],
    }
  },

  computed: {
    m_id() {
      return this.$route.query.id
    },

    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options1.find(item => item.value == this.search.search_type)
        .placeholder
    },
  },

  created() {
    this.search2 = _.assign({}, this.search, { m_id: this.m_id })
  },

  methods: {
    // 查看表单信息
    async formFieldsList(row) {
      const { data } = await this.$http({
        url: '/member/resultDetail',
        data: {
          me_id: row.me_id,
        },
      })
      this.fieldsList = data
      this.$nextTick(() => {
        this.fieldsStatus = true
      })
    },

    toSearch() {
      this.search2 = _.assign({}, this.search, { m_id: this.m_id })
    },

    async addmemberList(val) {
      const ids = val.list.map(item => item.uid)
      await this.$http({
        url: '/member/addMemberUser',
        data: {
          m_id: this.m_id,
          validity: val.time,
          uids: ids,
        },
        successMsg: true,
      })
      this.$refs.childDialogBox.reset()
      this.statusAddMember = false
    },

    //确认修改有效期
    async validStatusSubmit() {
      await this.$http({
        url: '/member/saveValid',
        data: {
          mu_id: this.modifyTimeList.mu_id,
          expiration_time: this.validEndtime / 1000,
        },
      })
      this.$refs.childDialogBox.reset()

      this.validStatusCancel()
    },
    // 有效期弹窗取消
    validStatusCancel() {
      this.validEndtime = ''
      this.validStatus = false
    },

    modifyTime(row) {
      this.modifyTimeList = row
      this.validEndtime = row.expiration_time * 1000
      this.$nextTick(() => {
        this.validStatus = true
      })
    },

    // 分组完成
    complete() {
      this.$refs.childDialogBox.reset()
      this.mobileGroupingStatus = false
    },

    // 分组
    modifyGroump(row) {
      this.selectTemplate = row
      this.$nextTick(() => {
        this.mobileGroupingStatus = true
      })
    },

    // 移除
    async del(row) {
      this.$confirm('确定移除该学员？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/member/remove',
            data: {
              mu_id: row.mu_id,
            },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.memberList {
  ::v-deep .el-dialog__header {
    padding-bottom: 0;
  }
  ::v-deep .el-dialog__body {
    padding-top: 20px;
  }
  .top {
    margin-bottom: 28px;

    .search {
      margin-top: 28px;
      .middle-space {
        font-size: 14px;
        line-height: 19px;
        margin: 0 10px;
        color: rgba(51, 51, 51, 1);
      }
      ::v-deep .el-form {
        flex-wrap: wrap;
      }
      .search_type {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .el-form-item__content {
          height: auto;
        }
        .el-form-item {
          width: 33.3%;
          margin-bottom: 20px;
        }
      }
      ::v-deep .el-input,
      ::v-deep .select {
        width: 100%;
        .el-input__inner {
          width: 100%;
        }
      }
      ::v-deep .search-item1 {
        display: flex;
        width: 300px;
        .select {
          width: 130px;
          input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .input {
          width: 100%;
          input {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      ::v-deep .search-item2,
      ::v-deep .search-item6 {
        .select {
          width: 160px;
        }
      }
      ::v-deep .search-item3,
      ::v-deep .search-item4 {
        .el-date-editor {
          width: 292px;
        }
        .el-input__icon {
          line-height: 27px;
        }
      }
      ::v-deep .search-item5,
      ::v-deep .search-item7,
      ::v-deep .search-item8 {
        width: 300px;
        .el-input {
          // width: 130px;
          position: relative;
          input[type='number'] {
            padding-right: 50px;
          }
          .el-input-group__append {
            position: absolute;
            right: 0;
            background: none;
            top: 6px;
            height: 100%;
            border: 0;
            // line-height: 36px;
            width: auto;
            padding: 0;
            padding-right: 10px;
          }
        }
      }
    }
  }
  .childDialogBox {
    .user_contain {
      display: flex;
      align-content: center;
      .tu {
        width: 28px;
        height: 28px;
        background: #939191;
        border-radius: 14px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
}
</style>
